<template>
  <v-card>
    <v-card-title>
       <span>Equipos</span>
     
       <v-btn dark color="indigo" class="mx-5" @click="listar()"><v-icon>autorenew</v-icon></v-btn>

      <v-text-field class="mx-5"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="Arrayflota"
      :search="search"
    >
    
    
    <template v-slot:[`item.icono`]="{ item }">
        <img  :src="CambiarImgUrl(item.icono)" v-bind:alt="item.icono"   style="width: 30px; height: 30px"/>
     </template>


      <template v-slot:[`item.s1`]="{ item }"> 
        <v-icon color="indigo">thermostat</v-icon>
        <span>{{item.s1}}</span>
     </template>


      <template v-slot:[`item.s2`]="{ item }"> 
        <v-icon color="indigo">thermostat</v-icon>
        <span>{{item.s2}}</span>
     </template>

         <template v-slot:[`item.s3`]="{ item }"> 
        <v-icon color="indigo">thermostat</v-icon>
        <span>{{item.s3}}</span>
     </template>

     
 <template v-slot:[`item.accion`]="{ item }"> 
       <v-btn icon @click="editItem(item)">
         <v-icon color="indigo">edit</v-icon>

       </v-btn>
       
       
     </template>

   

                               
                              
</v-data-table>

 <v-btn small dark color="success" class="mx-5 mb-5" @click="ActualizarGrid()">Sync AVL<v-icon>autorenew</v-icon></v-btn>


<v-dialog v-model="dialog" width="370px">
   <v-card>

     <v-toolbar dark color="indigo">
       <v-toolbar-title>Configuracion alerta :{{editedItem.nombre}}</v-toolbar-title>
       <v-spacer></v-spacer>
       <v-icon dark @click="dialog=false">close</v-icon>
     </v-toolbar>

        <v-card-text style="height:150px;" class="text--center">
        <v-row class="mt-5">
                  <v-col  cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.temp_max" label="temp_max">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.temp_min" label="temp_min"></v-text-field>
                  </v-col>
      
        </v-row>
    
        </v-card-text>

        <v-card-actions>
         <v-btn color="red" dark @click="GuardarDatos()" class="mx-2">Guardar</v-btn>
          <v-btn outlined color="indigo" @click="dialog=false">cancelar</v-btn>

</v-card-actions>

   </v-card>
</v-dialog>


  </v-card>
</template>

<script>

import axios from 'axios';
import { mapMutations, mapState } from "vuex";

  export default {
    data () {
      return {
        Arrayflota:[],
        search: '',
        headers: [
          { text: 'Accion', value: 'accion' },
          { text: 'Icono', value: 'icono' },
          { text: 'IdAvl', value: 'id_avl' },
          { text: 'nombre', value: 'nombre' },
          { text: 'placa', value: 'placa' },
          { text: 'Flota', value: 'flota' },

           { text: 'Temperatura Fecha', value: 'temp_fecha' },
           { text: 'Sensor 1', value: 's1' },
           { text: 'Sensor 2', value: 's2' },
           { text: 'Sensor 3', value: 's3' },
          
          { text: 'Actualizacion', value: 'ultimaTx'},

         
         

        ],
        dialog:false,
        detalle:{},
        editedItem:{},


      }
    },
    created () {
       this.listar();
       this.$root.$refs.Home.detenerTimer();
     
    },
    methods:{

       ...mapMutations(['mostrarLoading','ocultarLoading']),

         listar(){

                 this.Arrayflota=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`vehiculos/${this.usuario.id_usr_empresa._id}`,
                    config
                    ).then(function(response){
                        console.log(response);
                        me.Arrayflota=response.data;
                        me.ocultarLoading();
                    }).catch(function(error){ console.log(error); });

          },
 

          ActualizarGrid(){

             let config={headers:{ token:this.token}};
            let me=this;
              me.mostrarLoading({titulo:'actualizando con AVL'});
            axios.post('ActualizarAVL',{ 'id_empresa':this.usuario.id_usr_empresa._id,
                           
                          },
                        config
                      ).then(response=>{
                      
                          console.log(response);
                          me.ocultarLoading();
                          me.listar();

                      }).catch(e=>{ console.log(e) });            
         }, 
      
         CambiarImgUrl(picture){
            picture=picture.substring(0,picture.length - 5);
            if(picture.slice(-1)!='/'){
                    picture=picture.substring(0,picture.length - 1);
                }
                return (picture+'/E.png');
         },

         editItem(item){
          this.editedItem=item;
          this.dialog=true;

         },

          GuardarDatos(){

              let config={headers:{token:this.token}};

               this.mostrarLoading({titulo:'Accediendo a datos'});

               let id=this.editedItem._id;

                    axios.put(`vehiculo/${id}`,
                      {
                        'temp_min':this.editedItem.temp_min, 
                        'temp_max':this.editedItem.temp_max
                      },
                      config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.dialog=false;
                         this.listar();
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });


         },

      

      

    }, computed:{

       ...mapState(['usuario', 'token']),


    }
  }
</script>